import { Component } from 'solid-js'
import RowButton from '~/components/row-button/row-button'
import styles from '~/components/text-style-color-fill/text-style-color-fill.module.scss'
import TextStyleColorGradient from '~/components/text-style-color-gradient/text-style-color-gradient'
import TextStyleColor from '~/components/text-style-color/text-style-color'
import { TextStylesWidget } from '~/components/text-style-editor/text-style-editor.interfaces'
import { Gradient, TextProps } from '~/types/editor/text-styles'
import FilterIcon from '~/assets/icons/filter.svg'
import { translations } from '~/translations'

const TextStyleColorFill: Component<TextStylesWidget> = ({ textStyles, updateStyles, onFieldFocus, onFieldBlur }) => {
  const isGradient = () => typeof textStyles.props.fill !== 'string'

  const onGradientToggle = () => {
    if(typeof textStyles.props.fill !== 'string'){
      const color = textStyles.props.fill.colorStops[0].color
      updateStyles({
        props: {
          fill: color
        }
      })
    }else{
      const gradient: Gradient = {
        angle: 0,
        type: 'linear',
        colorStops: [
          {
            color: textStyles.props.fill,
            offset: 0
          },
          {
            color: '#ffffff',
            offset: 1
          }
        ]
      }
      updateStyles({
        props: {
          fill: gradient
        }
      })
    }
  }

  const toggleLabel = () => isGradient() ? translations().editor.typesetting.fields.removeGradient : translations().editor.typesetting.fields.addGradient

  const onChange = (value: TextProps['fill']) => {
    updateStyles({
      props: {
        fill: value
      }
    })
  }

  const onInput = (value: TextProps['fill']) => {
    updateStyles({
      props: {
        fill: value
      }
    }, {
      updateSelected: true,
      updateDB: false
    })
  }

  return (
    <div class={styles.container}>
      <div class={styles.labelGroup}>
        <div class={styles.label}>
          {translations().editor.typesetting.fields.color}
        </div>
        <RowButton
          onClick={onGradientToggle}
          icon={FilterIcon}
          label={toggleLabel()}
        />
      </div>
      {isGradient()
        ?
          <TextStyleColorGradient
            value={textStyles.props.fill as Gradient}
            onChange={onChange}
            onInput={onInput}
            onFocus={onFieldFocus}
            onBlur={onFieldBlur}
          />
        :
          <TextStyleColor
            value={textStyles.props.fill as string}
            onChange={onChange}
            onInput={onInput}
          />
      }
    </div>
  )
}

export default TextStyleColorFill
