import { InferOutput, nonEmpty, object, pipe, string } from 'valibot'

export interface TextStyleSaveModalProps{
  open: boolean
  setOpen: (value: boolean) => boolean
  onConfirm: (value: string) => void
}

export const CreateTextStyleSchema = object({
  path: pipe(string(), nonEmpty())
})

export type CreateTextStyleFormType = InferOutput<typeof CreateTextStyleSchema>