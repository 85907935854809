import { Component, Index } from 'solid-js'
import RowButton from '~/components/row-button/row-button'
import { TextStylesWidget } from '~/components/text-style-editor/text-style-editor.interfaces'
import styles from '~/components/text-styles-stroke-collection/text-styles-stroke-collection.module.scss'
import Add from '~/assets/icons/add.svg'
import TextStylesStrokeEntry from '~/components/text-styles-stroke-entry/text-styles-stroke-entry'
import { Stroke } from '~/types/editor/text-styles'
import { translations } from '~/translations'

const TextStylesStrokeCollection: Component<TextStylesWidget> = ({ textStyles, updateStyles, onFieldFocus, onFieldBlur }) => {
  const onAdd = () => {
    const newStroke: Stroke = {
      color: '#ffffff',
      width: 3
    }
    updateStyles({
      strokes: [
        ...textStyles.strokes,
        newStroke
      ]
    })
  }

  const onDelete = (index: number) => () => {
    const strokes = textStyles.strokes.toSpliced(index, 1)
    updateStyles({
      strokes
    })
  }

  const onStrokeChange = (index: number) => (stroke: Stroke) => {
    const strokes = textStyles.strokes
    strokes[index] = stroke
    updateStyles({
      strokes
    })
  }

  const onStrokeInput = (index: number) => (stroke: Stroke) => {
    const strokes = textStyles.strokes
    strokes[index] = stroke
    updateStyles({
      strokes
    }, {
      updateDB: false
    })
  }

  return (
    <div class={styles.container}>
      <div class={styles.label}>
        <div class={styles.label}>
          {translations().editor.typesetting.fields.strokes}
        </div>
        <RowButton
          icon={Add}
          onClick={onAdd}
        />
      </div>
      <div class={styles.strokes}>
        <Index each={textStyles.strokes}>
          {(stroke, index) => (
            <TextStylesStrokeEntry
              stroke={stroke()}
              onChange={onStrokeChange(index)}
              onInput={onStrokeInput(index)}
              onFocus={onFieldFocus}
              onBlur={onFieldBlur}
              onDelete={onDelete(index)}
            />
          )}
        </Index>
      </div>
    </div>
  )
}

export default TextStylesStrokeCollection
