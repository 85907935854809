import { Component, For, createResource, createSignal } from 'solid-js'
import { TextStyleColorGradientProps } from '~/components/text-style-color-gradient/text-style-color-gradient.interfaces'
import styles from '~/components/text-style-color-gradient/text-style-color-gradient.module.scss'
import TextStyleColor from '~/components/text-style-color/text-style-color'
import TextStyleField from '~/components/text-style-field/text-style-field'
import { getGradientCSS } from '~/services/fonts/text-styles'
import { InputEvent } from '~/types/editor/input-events'

const TextStyleColorGradient: Component<TextStyleColorGradientProps> = ({ onChange, onInput, onFocus, onBlur, value: gradient }) => {
  const [moveableColorStop, setMoveableColorStop] = createSignal<number>()
  const [selectedColorStopIndex, setSelectedColorStopIndex] = createSignal<number>(0)
  const selectedColorStop = () => gradient.colorStops[selectedColorStopIndex()]
  const [colorStops] = createResource(() => gradient.colorStops)

  const onAngleChange = ({ updateDB = false }) => (angle: string) => {
    const newValue = parseInt(angle)
    const isValid = newValue > 0
    if (updateDB) {
      const cleanValue = isValid ? newValue : 1
      onChange({
        ...gradient,
        angle: cleanValue
      })
    } else {
      if (isValid) {
        onInput({
          ...gradient,
          angle: newValue
        })
      }
    }
  }

  const onColorStopOffsetChange = (props: { index: number, updateDB: boolean }) => (event: InputEvent) => {
    const colorStops = [...gradient.colorStops]
    colorStops[props.index].offset = parseFloat(event.target.value)

    if(props.updateDB){
      onChange({
        ...gradient,
        colorStops
      })
    }else{
      onInput({
        ...gradient,
        colorStops
      })
    }
  }

  const onColorStopColorChange = (props: { index: number, updateDB: boolean }) => (newColor: string) => {
    const colorStops = [...gradient.colorStops]
    colorStops[props.index].color = newColor

    if(props.updateDB){
      onChange({
        ...gradient,
        colorStops
      })
    }else{
      onInput({
        ...gradient,
        colorStops
      })
    }
  }

  return (
    <>
      <div class={styles.fields}>
        <TextStyleField
          value={gradient.angle}
          onChange={onAngleChange({ updateDB: true })}
          onInput={onAngleChange({ updateDB: false })}
          onFocus={onFocus}
          onBlur={onBlur}
          suffix='°'
          inputProps={{
            min: -360,
            max: 360,
            step: 45
          }}
        />
        <TextStyleColor
          value={selectedColorStop().color}
          onInput={onColorStopColorChange({
            index: selectedColorStopIndex(),
            updateDB: false
          })}
          onChange={onColorStopColorChange({
            index: selectedColorStopIndex(),
            updateDB: true
          })}
        />
      </div>
      <div class={styles.gradientEditor} onmouseleave={() => setMoveableColorStop(undefined)}>
        <For each={colorStops()}>
          {(colorStop, index) => (
            <div
              classList={{
                [styles.colorStop]: true,
                [styles.isMoveable]: moveableColorStop() === index()
              }}
              style={{
                '--color-stop': gradient.colorStops[index()].color
              }}
              onMouseDown={() => setSelectedColorStopIndex(index())}
            >
              <div
                class={styles.colorStopChevron}
                style={{
                  '--position': gradient.colorStops[index()].offset
                }}
                onmouseenter={() => setMoveableColorStop(index())}
              />
              <input
                type='range'
                value={colorStop.offset}
                class={styles.colorStopInput}
                min={0}
                max={1}
                step={0.01}
                onChange={onColorStopOffsetChange({
                  index: index(),
                  updateDB: true
                })}
                onInput={onColorStopOffsetChange({
                  index: index(),
                  updateDB: false
                })}
              />
            </div>
          )}
        </For>
        <div
          class={styles.gradient}
          style={getGradientCSS(gradient)}
        />
      </div>
    </>
  )
}

export default TextStyleColorGradient
