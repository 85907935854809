import { Component, Index } from 'solid-js'
import RowButton from '~/components/row-button/row-button'
import TextStylesShadowEntry from '~/components/text-styles-shadow-entry/text-styles-shadow-entry'
import Add from '~/assets/icons/add.svg'
import styles from '~/components/text-styles-shadows-collection/text-styles-shadows-collection.module.scss'
import { TextStylesWidget } from '~/components/text-style-editor/text-style-editor.interfaces'
import { Shadow } from '~/types/editor/text-styles'
import { translations } from '~/translations'

const TextStylesShadowsCollection: Component<TextStylesWidget> = ({ textStyles, updateStyles, onFieldFocus, onFieldBlur }) => {
  const onAdd = () => {
    const newShadow: Shadow = {
      color: '#000000',
      offsetX: 0,
      offsetY: 0,
      blur: 4
    }
    updateStyles({
      shadows: [
        ...textStyles.shadows,
        newShadow
      ]
    })
  }

  const onDelete = (index: number) => () => {
    const shadows = textStyles.shadows.toSpliced(index, 1)
    updateStyles({
      shadows
    })
  }

  const onShadowChange = (index: number) => (shadow: Shadow) => {
    const shadows = textStyles.shadows
    shadows[index] = shadow
    updateStyles({
      shadows
    })
  }

  const onShadowInput = (index: number) => (shadow: Shadow) => {
    const shadows = textStyles.shadows
    shadows[index] = shadow
    updateStyles({
      shadows
    }, {
      updateDB: false
    })
  }

  return (
    <div class={styles.container}>
      <div class={styles.label}>
        <div class={styles.label}>
          {translations().editor.typesetting.fields.shadows}
        </div>
        <RowButton
          icon={Add}
          onClick={onAdd}
        />
      </div>
      <div class={styles.shadows}>
        <Index each={textStyles.shadows}>
          {(shadow, index) => (
            <TextStylesShadowEntry
              shadow={shadow()}
              onChange={onShadowChange(index)}
              onInput={onShadowInput(index)}
              onFocus={onFieldFocus}
              onBlur={onFieldBlur}
              onDelete={onDelete(index)}
            />
          )}
        </Index>
      </div>
    </div>
  )
}

export default TextStylesShadowsCollection
