import { useParams } from '@solidjs/router'
import TypesettingView from '~/components/typesetting-view/typesetting-view'
import { TypesettingParams } from '~/types/routes/series/typesetting'

export default function Typesetting() {
  const { id } = useParams<TypesettingParams>()
  return (
    <>
      <TypesettingView chapterId={id} />
    </>
  )
}
