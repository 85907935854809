import { Component } from 'solid-js'
import { TextStyleColorProps } from '~/components/text-style-color/text-style-color.interfaces'
import styles from '~/components/text-style-color/text-style-color.module.scss'

const TextStyleColor: Component<TextStyleColorProps> = ({ onChange, onInput, value }) => {
  let ref!: HTMLInputElement

  const onChangeHandler = () => {
    onChange(ref.value)
  }

  const onInputHandler = () => {
    onInput(ref.value)
  }

  const colorStyles = () => ({
    background: value
  })

  return (
    <div class={styles.container}>
      <div class={styles.color} style={colorStyles()} />
      <input
        class={styles.input}
        type='color'
        onChange={onChangeHandler}
        onInput={onInputHandler}
        ref={ref}
        value={value}
      />
    </div>
  )
}

export default TextStyleColor
