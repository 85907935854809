import { Component } from 'solid-js'
import TextStyleToggle from '~/components/text-style-toggle/text-style-toggle'
import { TextStylesWidget } from '~/components/text-style-editor/text-style-editor.interfaces'
import styles from '~/components/text-style-toggles/text-style-toggles.module.scss'
import Caps from '~/assets/icons/caps.svg'
import Center from '~/assets/icons/align-center.svg'
import Left from '~/assets/icons/align-left.svg'
import Right from '~/assets/icons/align-right.svg'
import Justify from '~/assets/icons/align-justify.svg'

const TextStyleToggles: Component<TextStylesWidget> = ({ updateStyles, textStyles }) => {
  const onCapsToggle = () => {
    updateStyles({
      props: {
        uppercase: !textStyles?.props.uppercase
      }
    })
  }

  const onAlignLeft = () => {
    updateStyles({
      props: {
        textAlign: 'left'
      }
    })
  }

  const onCenter = () => {
    updateStyles({
      props: {
        textAlign: 'center'
      }
    })
  }

  const onAlignRight = () => {
    updateStyles({
      props: {
        textAlign: 'right'
      }
    })
  }

  const onJustify = () => {
    updateStyles({
      props: {
        textAlign: 'justify'
      }
    })
  }

  return (
    <div class={styles.container}>
      <TextStyleToggle
        toggled={Boolean(textStyles?.props.uppercase)}
        onClick={onCapsToggle}
        icon={Caps}
      />
      <TextStyleToggle
        toggled={textStyles?.props.textAlign === 'left'}
        onClick={onAlignLeft}
        icon={Left}
      />
      <TextStyleToggle
        toggled={textStyles?.props.textAlign === 'center'}
        onClick={onCenter}
        icon={Center}
      />
      <TextStyleToggle
        toggled={textStyles?.props.textAlign === 'right'}
        onClick={onAlignRight}
        icon={Right}
      />
      <TextStyleToggle
        toggled={textStyles?.props.textAlign === 'justify'}
        onClick={onJustify}
        icon={Justify}
      />
    </div>
  )
}

export default TextStyleToggles
