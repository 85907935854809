import { Component } from 'solid-js'
import { TextStylesWidget } from '~/components/text-style-editor/text-style-editor.interfaces'
import TextStyleField from '~/components/text-style-field/text-style-field'

const TextStyleLetterSpacing: Component<TextStylesWidget> = ({ updateStyles, textStyles, onFieldBlur, onFieldFocus }) => {
  const onChange = (value: string) => {
    const valueIsTransitory = value === '-' || value === ''
    if(!valueIsTransitory){
      const computedValue = parseInt(value) / 100
      updateStyles({
        props: {
          letterSpacing: computedValue
        }
      })
    }
  }

  const value = () => {
    return Math.round(textStyles.props.letterSpacing * 100)
  }

  return (
    <TextStyleField
      label='Caractères'
      onChange={onChange}
      onInput={onChange}
      onFocus={onFieldFocus}
      onBlur={onFieldBlur}
      value={value()}
      suffix='%'
    />
  )
}

export default TextStyleLetterSpacing
