import { Component } from 'solid-js'
import { TextStylesWidget } from '~/components/text-style-editor/text-style-editor.interfaces'
import TextStyleField from '~/components/text-style-field/text-style-field'

const TextStyleLineHeight: Component<TextStylesWidget> = ({ updateStyles, textStyles, onFieldBlur, onFieldFocus }) => {
  const onInput = (value: string) => {
    const computedValue = parseInt(value) / 100
    const isValid = computedValue >= 0
    if(isValid){
      updateStyles({
        props: {
          lineHeight: computedValue
        }
      })
    }
  }

  const onChange = (value: string) => {
    const computedValue = parseInt(value) / 100
    const constrainedValue = computedValue >= 0 ? computedValue : 1
    updateStyles({
      props: {
        lineHeight: constrainedValue
      }
    })
  }

  const value = () => {
    return Math.round(textStyles.props.lineHeight * 100)
  }

  return (
    <TextStyleField
      label='Interligne'
      onChange={onChange}
      onInput={onInput}
      onFocus={onFieldFocus}
      onBlur={onFieldBlur}
      value={value()}
      suffix='%'
    />
  )
}

export default TextStyleLineHeight
