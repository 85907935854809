import { Component, createSignal, JSX } from 'solid-js'
import TogglePill from '~/components/toggle-pill/toggle-pill'
import { PillOption } from '~/components/toggle-pill/toggle-pill.interfaces'
import TypesetEntry from '~/components/typeset-entry/typeset-entry'
import { TypesetListProps } from '~/components/typeset-list/typeset-list.interfaces'
import styles from '~/components/typeset-list/typeset-list.module.scss'
import Translation from '~/editor/translation/translation'
import { translations } from '~/services/current-document/translations'
import { setTranslationsList } from '~/services/current-document/translations-list'
import { translations as translationsDict } from '~/translations'

const TypesetList: Component<TypesetListProps> = ({ document }) => {
  const [filterBy, setFilterBy] = createSignal<PillOption[]>([
    {
      label: translationsDict().editor.translation.filterOptions.all,
      value: 'all',
      checked: true
    },
    {
      label: translationsDict().editor.translation.filterOptions.corrections,
      value: 'corrections',
      checked: false
    },
    {
      label: translationsDict().editor.translation.filterOptions.comments,
      value: 'comments',
      checked: false
    },
    {
      label: translationsDict().editor.translation.filterOptions.onomatopoeias,
      value: 'onomatopoeas',
      checked: false
    }
  ])

  const filterByCorrections = (entry: Translation) => {
    const hasCorrection = entry.versions[entry.versions.length - 1].isCorrection
    return hasCorrection
  }

  const filterByComments = (entry: Translation) => {
    const hasOpenCommentThread = entry.comments.length > 0 && !entry.comments[entry.comments.length - 1].resolved
    return hasOpenCommentThread
  }

  const filterByOnomatopoeas = (entry: Translation) => {
    return entry.versions[entry.versions.length - 1].isOnomatopoeia
  }

  const filteredEntries = () => {
    let entries = translations()
    const currentFilter = filterBy().find(option => option.checked)?.value ?? 'all'
    if (currentFilter === 'corrections') entries = entries.filter(filterByCorrections)
    if (currentFilter === 'comments') entries = entries.filter(filterByComments)
    if (currentFilter === 'onomatopoeas') entries = entries.filter(filterByOnomatopoeas)
    return entries
  }

  const elementsList = () => {
    let currentPageIndex = 0
    const list: JSX.Element = []
    list.push(
      <div class={styles.pageIndex}>
        <span class={styles.label}>
          {translationsDict().editor.page}
        </span>
        <span class={styles.number}>
          {currentPageIndex + 1}
        </span>
      </div>
    )

    filteredEntries().forEach((entry, index) => {
      if(entry.page.index !== currentPageIndex){
        currentPageIndex = entry.page.index
        list.push(
          <div class={styles.pageIndex}>
            <span class={styles.label}>
              {translationsDict().editor.page}
            </span>
            <span class={styles.number}>
              {currentPageIndex + 1}
            </span>
          </div>
        )
      }

      list.push(
        <TypesetEntry
          index={index}
          entry={entry}
          document={document}
        />
      )
    })

    return list
  }

  const registerRef = (element: HTMLDivElement) => {
    setTranslationsList(element)
  }

  return (
    <>
      <div class={styles.toolbar}>
        <div class={styles.toolEntry}>
          <div class={styles.label}>
            {translationsDict().general.actions.display}
          </div>
          <TogglePill
            options={filterBy()}
            setOptions={setFilterBy}
          />
        </div>
      </div>
      <div class={styles.typesetList} ref={registerRef}>
        {elementsList()}
      </div>
    </>
  )
}

export default TypesetList
