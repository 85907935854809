import { Component } from 'solid-js'
import { TextStyleEntryProps } from '~/components/text-style-entry/text-style-entry.interfaces'
import styles from '~/components/text-style-entry/text-style-entry.module.scss'
import Trash from '~/assets/icons/delete.svg'
import TextStylePreview from '~/components/text-style-preview/text-style-preview'

const TextStyleEntry: Component<TextStyleEntryProps> = ({ current, textStyle, onClick, onDelete, index }) => {
  const modifierKey = window.navigator.platform.includes('Mac') ? '⌥' : 'Alt'

  const deleteHandler = (event: MouseEvent) => {
    event.stopPropagation()
    if(onDelete){
      onDelete()
    }
  }

  return (
    <div
      classList={{
        [styles.textStyleEntry]: true,
        [styles.current]: current
      }}
      onClick={onClick}
    >
      <div class={styles.shortcut}>
        <strong>{modifierKey}</strong>+<strong>{index + 1}</strong>
      </div>

      <div class={styles.preview}>
        <TextStylePreview textStyle={textStyle} />
      </div>

      <button class={styles.delete} onClick={deleteHandler}>
        <Trash class={styles.deleteIcon} />
      </button>
    </div>
  )
}

export default TextStyleEntry
