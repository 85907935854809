import { Component, For, Show, createSignal } from 'solid-js'
import styles from '~/components/text-style-font-variant-select/text-style-font-variant-select.module.scss'
import Chevron from '~/assets/icons/chevron-down.svg'
import { FontData } from '~/local-fonts'
import { TextStylesWidget } from '~/components/text-style-editor/text-style-editor.interfaces'
import { getFontCSS } from '~/services/fonts/font-css'

const TextStyleFontVariantSelect: Component<TextStylesWidget> = ({ updateStyles, textStyles }) => {
  const [toggled, setToggled] = createSignal(false)
  const fontFamily = () => textStyles.props.fontFamily
  const fontVariant = () => textStyles.props.fontVariant

  const onEscapeHandler = (event: KeyboardEvent) => {
    if (event.code === 'Escape') {
      onBlurHandler()
    }
  }

  const onBlurHandler = () => {
    window.removeEventListener('click', onBlurHandler)
    window.removeEventListener('keydown', onEscapeHandler)
    onBlur()
  }

  const onBlur = () => {
    setToggled(false)
  }

  const onSelect = (fontVariant: FontData) => () => {
    updateStyles({
      props: {
        fontVariant
      }
    })
    setToggled(false)
  }

  const onToggle = () => {
    setToggled(value => !value)
    if (toggled()) {
      setTimeout(() => {
        window.addEventListener('click', onBlurHandler)
        window.addEventListener('keydown', onEscapeHandler)
      })
    }
  }

  const optionsClasses = () => ({
    [styles.options]: true,
    [styles.toggled]: toggled()
  })

  return (
    <div class={styles.container}>
      <button
        class={styles.select}
        onClick={onToggle}
      >
        <Show when={fontVariant()}>
          {variant => (
            <div class={styles.fontEntry} style={getFontCSS(variant())}>
              {variant().style}
            </div>
          )}
        </Show>

        <Chevron class={styles.icon} />
      </button>

      <div classList={optionsClasses()}>
        <Show when={fontFamily()}>
          {fontFamily => (
            <For each={fontFamily().styles}>
              {variant => (
                <button
                  class={styles.fontEntry}
                  style={getFontCSS(variant)}
                  onClick={onSelect(variant)}
                >
                  {variant.style}
                </button>
              )}
            </For>
          )}
        </Show>
      </div>
    </div>
  )
}

export default TextStyleFontVariantSelect
