import { Component } from 'solid-js'
import RowButton from '~/components/row-button/row-button'
import TextStyleColor from '~/components/text-style-color/text-style-color'
import TextStyleField from '~/components/text-style-field/text-style-field'
import { TextStylesStrokeEntryProps } from '~/components/text-styles-stroke-entry/text-styles-stroke-entry.interfaces'
import styles from '~/components/text-styles-stroke-entry/text-styles-stroke-entry.module.scss'
import Delete from '~/assets/icons/remove.svg'

const TextStylesStrokeEntry: Component<TextStylesStrokeEntryProps> = ({ onChange, onInput, onFocus, onBlur, onDelete, stroke }) => {
  const onWidthChange = ({ updateDB = false }) => (width: string) => {
    const newValue = parseFloat(width)
    const isValid = newValue > 0
    if (updateDB) {
      const cleanValue = isValid ? newValue : 1
      onChange({
        ...stroke,
        width: cleanValue
      })
    } else {
      if(isValid){
        onChange({
          ...stroke,
          width: newValue
        })
      }  
    }
  }

  const onColorChange = ({ updateDB = false }) => (color: string) => {
    if(updateDB){
      onChange({
        ...stroke,
        color
      })
    }else{
      onInput({
        ...stroke,
        color
      })
    }
  }

  return (
    <div class={styles.strokeEntry}>
      <TextStyleField
        onChange={onWidthChange({ updateDB: true })}
        onInput={onWidthChange({ updateDB: false })}
        onFocus={onFocus}
        onBlur={onBlur}
        value={stroke.width}
      />
      <TextStyleColor
        value={stroke.color}
        onChange={onColorChange({ updateDB: true })}
        onInput={onColorChange({ updateDB: false })}
      />
      <RowButton
        icon={Delete}
        onClick={onDelete}
      />
    </div>
  )
}

export default TextStylesStrokeEntry
