import { Component, For, createSignal } from 'solid-js'
import styles from '~/components/text-style-picker/text-style-picker.module.scss'
import Chevron from '~/assets/icons/chevron-down.svg'
import TextStyleEntry from '~/components/text-style-entry/text-style-entry'
import { selectedTypesetTexts } from '~/services/current-document/typeset-texts'
import { textStyles as currentTextStyles, updateStyles } from '~/services/current-document/text-styles'
import { TextStyles } from '~/types/editor/text-styles'
import { TextStylePickerProps } from '~/components/text-style-picker/text-style-picker.interfaces'
import { deleteTextStyle } from '~/services/database/text-styles'
import ConfirmationModal from '~/components/confirmation-modal/confirmation-modal'
import { translations } from '~/translations'
import EmptyIcon from '~/assets/icons/document-remove.svg'
import EmptyState from '~/components/empty-state/empty-state'

const TextStylePicker: Component<TextStylePickerProps> = ({ textStyles, refetchStyles }) => {
  const currentStyle = () => {
    let currentStyle = <span class={styles.dim}>{translations().editor.typesetting.textStyle.notSaved}</span>
    const selectedStyle = currentTextStyles()
    if (selectedStyle && selectedStyle.path) {
      currentStyle = <span>{selectedStyle.path}</span>
    }
    return currentStyle
  }
  const [toggled, setToggled] = createSignal(false)
  const [textStylesToDelete, setTextStylesToDelete] = createSignal<TextStyles>()
  const [deleteConfirmation, setDeleteConfirmation] = createSignal(false)

  const onStyleClick = (textStyle: TextStyles) => () => {
    const selectionExists = Boolean(selectedTypesetTexts().length > 0)
    updateStyles(textStyle, {
      updateDB: selectionExists,
      updateSelected: selectionExists,
      resetCharStyles: true
    })
    setToggled(false)
  }

  const onStyleDeleteConfirm = (textStyle: TextStyles) => () => {
    setTextStylesToDelete(textStyle)
    setDeleteConfirmation(true)
  }

  const onStyleDelete = async () => {
    const id = textStylesToDelete()?.meta?.id
    if (id) {
      await deleteTextStyle(id)
      refetchStyles()
    }
    setDeleteConfirmation(false)
  }

  const onEscapeHandler = (event: KeyboardEvent) => {
    if (event.code === 'Escape') {
      onBlurHandler()
    }
  }

  const onBlurHandler = () => {
    window.removeEventListener('click', onBlurHandler)
    window.removeEventListener('keydown', onEscapeHandler)
    onBlur()
  }

  const onBlur = () => {
    setToggled(false)
  }

  const onToggle = () => {
    setToggled(value => !value)
    if (toggled()) {
      setTimeout(() => {
        window.addEventListener('click', onBlurHandler)
        window.addEventListener('keydown', onEscapeHandler)
      })
    }
  }

  const optionsClasses = () => ({
    [styles.options]: true,
    [styles.toggled]: toggled()
  })

  return (
    <>
      <ConfirmationModal
        open={deleteConfirmation()}
        setOpen={setDeleteConfirmation}
        title={translations().editor.typesetting.textStyle.deleteConfirmation.title}
        description={textStylesToDelete()?.path ?? ''}
        onConfirm={onStyleDelete}
        type='danger'
      />

      <div class={styles.textStylePicker}>
        <button
          class={styles.select}
          onClick={onToggle}
        >
          {currentStyle()}
          <Chevron class={styles.icon} />
        </button>

        <div classList={optionsClasses()}>
          <For each={textStyles} fallback={(
            <EmptyState
              icon={EmptyIcon}
              label={translations().editor.typesetting.textStyle.emptyState.title}
            />
          )}>
            {(textStyle, index) => (
              <TextStyleEntry
                onClick={onStyleClick(textStyle)}
                onDelete={onStyleDeleteConfirm(textStyle)}
                current={false}
                textStyle={textStyle}
                index={index()}
              />
            )}
          </For>
        </div>
      </div>
    </>
  )
}

export default TextStylePicker
