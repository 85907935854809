import { Component, createResource, createSignal, onCleanup, onMount } from 'solid-js'
import SmallButton from '~/components/small-button/small-button'
import styles from '~/components/text-style-editor/text-style-editor.module.scss'
import TextStyleFontSelect from '~/components/text-style-font-select/text-style-font-select'
import TextStyleFontVariantSelect from '~/components/text-style-font-variant-select/text-style-font-variant-select'
import TextStyleToggles from '~/components/text-style-toggles/text-style-toggles'
import TextStyleFontSize from '~/components/text-style-font-size/text-style-font-size'
import TextStyleLineHeight from '~/components/text-style-line-height/text-style-line-height'
import TextStyleLetterSpacing from '~/components/text-style-letter-spacing/text-style-letter-spacing'
import { textStyles, updateStyles } from '~/services/current-document/text-styles'
import TextStyleColorFill from '~/components/text-style-color-fill/text-style-color-fill'
import TextStylesStrokeCollection from '~/components/text-styles-stroke-collection/text-styles-stroke-collection'
import { getTextStyles, insertTextStyle } from '~/services/database/text-styles'
import TextStylesShadowsCollection from '~/components/text-styles-shadows-collection/text-styles-shadows-collection'
import TextStyleSaveModal from '~/components/text-style-save-modal/text-style-save-modal'
import { chapter } from '~/services/current-document/chapter'
import TextStylePicker from '~/components/text-style-picker/text-style-picker'
import { selectedTypesetTexts } from '~/services/current-document/typeset-texts'
import { TextStyleEditorProps } from '~/components/text-style-editor/text-style-editor.interfaces'
import { translations } from '~/translations'

const TextStyleEditor: Component<TextStyleEditorProps> = ({ document }) => {
  const seriesId = () => chapter()?.comicId
  const [textStylesCollection, { refetch: refetchStyles }] = createResource(seriesId, getTextStyles)
  const [saveConfirmation, setSaveConfirmation] = createSignal(false)

  const setDocumentFocus = (value: boolean) => {
    document.hasFocus = value
  }

  const openStyleSaveModal = () => {
    setDocumentFocus(false)
    setSaveConfirmation(true)
  }

  const onStyleSave = async (path: string) => {
    const seriesId = chapter()?.comicId
    const styles = textStyles()
    styles.path = path
    const selectionExists = Boolean(selectedTypesetTexts().length > 0)

    if(seriesId){
      await insertTextStyle(styles, path, seriesId)
      updateStyles(styles, {
        updateDB: selectionExists,
        updateSelected: selectionExists
      })
      refetchStyles()
    }

    setDocumentFocus(true)
  }

  const onKeyDownHandler = (event: KeyboardEvent) => {
    const chordKey = event.altKey
    const styles = textStylesCollection.latest
    if (styles && styles[0] && event.code === 'Digit1' && chordKey && !event.shiftKey) {
      updateStyles(styles[0])
    }
    if (styles && styles[1] && event.code === 'Digit2' && chordKey && !event.shiftKey) {
      updateStyles(styles[1])
    }
    if (styles && styles[2] && event.code === 'Digit3' && chordKey && !event.shiftKey) {
      updateStyles(styles[2])
    }
    if (styles && styles[3] && event.code === 'Digit4' && chordKey && !event.shiftKey) {
      updateStyles(styles[3])
    }
    if (styles && styles[4] && event.code === 'Digit5' && chordKey && !event.shiftKey) {
      updateStyles(styles[4])
    }
    if (styles && styles[5] && event.code === 'Digit6' && chordKey && !event.shiftKey) {
      updateStyles(styles[5])
    }
    if (styles && styles[6] && event.code === 'Digit7' && chordKey && !event.shiftKey) {
      updateStyles(styles[6])
    }
    if (styles && styles[7] && event.code === 'Digit8' && chordKey && !event.shiftKey) {
      updateStyles(styles[7])
    }
    if (styles && styles[8] && event.code === 'Digit9' && chordKey && !event.shiftKey) {
      updateStyles(styles[8])
    }
    if (styles && styles[9] && event.code === 'Digit0' && chordKey && !event.shiftKey) {
      updateStyles(styles[9])
    }
  }

  const onFieldFocus = () => {
    setDocumentFocus(false)
  }

  const onFieldBlur = () => {
    setDocumentFocus(true)
  }

  onMount(() => {
    window.addEventListener('keydown', onKeyDownHandler)
  })

  onCleanup(() => {
    window.removeEventListener('keydown', onKeyDownHandler)
  })

  return (
    <div class={styles.editor}>
      <div class={styles.currentStyle}>
        <TextStylePicker
          textStyles={textStylesCollection.latest ?? []}
          refetchStyles={refetchStyles}
        />
        <SmallButton
          onClick={openStyleSaveModal}
          borderless
        >
          {translations().general.actions.save}
        </SmallButton>
      </div>

      <TextStyleSaveModal
        open={saveConfirmation()}
        setOpen={setSaveConfirmation}
        onConfirm={onStyleSave}
      />

      <div class={styles.fontSettings}>
        <TextStyleFontSelect
          textStyles={textStyles()}
          updateStyles={updateStyles}
          onFieldFocus={onFieldFocus}
          onFieldBlur={onFieldBlur}
        />
        <TextStyleFontVariantSelect
          textStyles={textStyles()}
          updateStyles={updateStyles}
          onFieldFocus={onFieldFocus}
          onFieldBlur={onFieldBlur}
        />
      </div>

      <div class={styles.fields}>
        <TextStyleFontSize
          textStyles={textStyles()}
          updateStyles={updateStyles}
          onFieldFocus={onFieldFocus}
          onFieldBlur={onFieldBlur}
        />
        <TextStyleToggles
          textStyles={textStyles()}
          updateStyles={updateStyles}
          onFieldFocus={onFieldFocus}
          onFieldBlur={onFieldBlur}
        />
        <TextStyleLineHeight
          textStyles={textStyles()}
          updateStyles={updateStyles}
          onFieldFocus={onFieldFocus}
          onFieldBlur={onFieldBlur}
        />
        <TextStyleLetterSpacing
          textStyles={textStyles()}
          updateStyles={updateStyles}
          onFieldFocus={onFieldFocus}
          onFieldBlur={onFieldBlur}
        />
      </div>

      <div class={styles.fields}>
        <TextStyleColorFill
          textStyles={textStyles()}
          updateStyles={updateStyles}
          onFieldFocus={onFieldFocus}
          onFieldBlur={onFieldBlur}
        />
        <TextStylesStrokeCollection
          textStyles={textStyles()}
          updateStyles={updateStyles}
          onFieldFocus={onFieldFocus}
          onFieldBlur={onFieldBlur}
        />
        <TextStylesShadowsCollection
          textStyles={textStyles()}
          updateStyles={updateStyles}
          onFieldFocus={onFieldFocus}
          onFieldBlur={onFieldBlur}
        />
      </div>
    </div>
  )
}

export default TextStyleEditor
