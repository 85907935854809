import { Component } from 'solid-js'
import { TextStylesWidget } from '~/components/text-style-editor/text-style-editor.interfaces'
import TextStyleField from '~/components/text-style-field/text-style-field'
import { translations } from '~/translations'

const TextStyleFontSize: Component<TextStylesWidget> = ({ updateStyles, textStyles, onFieldBlur, onFieldFocus }) => {
  const onInput = (value: string) => {
    const computedValue = parseFloat(value)
    const isValid = computedValue >= 6
    if (isValid){
      updateStyles({
        props: {
          fontSize: computedValue
        }
      })
    }
  }

  const onChange = (value: string) => {
    const computedValue = parseFloat(value)
    const cleanValue = computedValue >= 6 ? computedValue : 6
    updateStyles({
      props: {
        fontSize: cleanValue
      }
    })
  }

  return (
    <TextStyleField
      label={translations().editor.typesetting.fields.fontSize}
      onInput={onInput}
      onChange={onChange}
      onFocus={onFieldFocus}
      onBlur={onFieldBlur}
      value={textStyles.props.fontSize}
    />
  )
}

export default TextStyleFontSize
