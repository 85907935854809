import { Component } from 'solid-js'
import { TextStyleToggleProps } from '~/components/text-style-toggle/text-style-toggle.interfaces'
import styles from '~/components/text-style-toggle/text-style-toggle.module.scss'

const TextStyleToggle: Component<TextStyleToggleProps> = ({ onClick, toggled, ...props }) => {
  const Icon = props.icon
  const onToggle = (event: MouseEvent) => {
    event.preventDefault()
    onClick(!toggled)
  }

  return (
    <button
      classList={{
        [styles.toggle]: true,
        [styles.toggled]: toggled
      }}
      onClick={onToggle}
    >
      <Icon class={styles.icon} />
    </button>
  )
}

export default TextStyleToggle
