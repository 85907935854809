import { Component } from 'solid-js'
import Button from '~/components/button/button'
import { CreateTextStyleFormType, CreateTextStyleSchema, TextStyleSaveModalProps } from '~/components/text-style-save-modal/text-style-save-modal.interfaces'
import styles from '~/components/text-style-save-modal/text-style-save-modal.module.scss'
import CreateIcon from '~/assets/icons/add.svg'
import TextField from '~/components/fields/text/text'
import { SubmitHandler, createForm, valiForm } from '@modular-forms/solid'
import Modal from '~/components/modal/modal'
import { translations } from '~/translations'

const TextStyleSaveModal: Component<TextStyleSaveModalProps> = ({ open, setOpen, onConfirm }) => {
  const onClose = () => {
    setOpen(false)
  }

  const [, { Form, Field }] = createForm<CreateTextStyleFormType>({
    initialValues: {
      path: translations().editor.typesetting.textStyle.form.create.defaultValue
    },
    validate: valiForm(CreateTextStyleSchema)
  })

  const onSubmit: SubmitHandler<CreateTextStyleFormType> = (values) => {
    onConfirm(values?.path)
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={translations().editor.typesetting.textStyle.form.create.title}
    >
      <Form onSubmit={onSubmit} class={styles.form}>
        <Field name='path'>
          {(field, props) => (
            <TextField
              label={translations().editor.typesetting.textStyle.form.create.name}
              type='text'
              placeholder={translations().editor.typesetting.textStyle.form.create.placeholder}
              {...field}
              {...props}
            />
          )}
        </Field>

        <div class={styles.buttons}>
          <Button onClick={onClose}>
            {translations().general.actions.cancel}
          </Button>
          <Button
            style='lagoon'
            type='submit'
            icon={CreateIcon}
          >
            {translations().general.actions.add}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default TextStyleSaveModal