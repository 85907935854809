import { Component } from 'solid-js'
import { TextStyleFieldProps } from '~/components/text-style-field/text-style-field.interfaces'
import styles from '~/components/text-style-field/text-style-field.module.scss'
import { isUppercase } from '~/helpers/general'

const TextStyleField: Component<TextStyleFieldProps> = ({ label, prefix, suffix, value, onChange, onInput, onFocus, onBlur, inputProps }) => {
  let ref!: HTMLInputElement 
  const onChangeHandler = () => {
    onChange(ref.value)
  }
  const onInputHandler = () => {
    onInput(ref.value)
  }

  return (
    <div class={styles.field}>
      {label && (
        <div class={styles.label}>
          {label}
        </div>
      )}
      
      <div class={styles.value}>
        {prefix && (
          <div classList={{
            [styles.prefix]: true,
            [styles.majAffix]: isUppercase(prefix),
          }}>
            {prefix}
          </div>
        )}

        <input
          ref={ref}
          type='number'
          class={styles.input}
          value={value}
          onChange={onChangeHandler}
          onInput={onInputHandler}
          onFocus={onFocus}
          onBlur={onBlur}
          {...inputProps}
        />
        
        {suffix && (
          <div classList={{
            [styles.suffix]: true,
            [styles.majAffix]: isUppercase(suffix),
          }}>
            {suffix}
          </div>
        )}
      </div>
    </div>
  )
}

export default TextStyleField
