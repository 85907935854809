import { Component } from 'solid-js'
import RowButton from '~/components/row-button/row-button'
import TextStyleColor from '~/components/text-style-color/text-style-color'
import TextStyleField from '~/components/text-style-field/text-style-field'
import { TextStylesShadowEntryProps } from '~/components/text-styles-shadow-entry/text-styles-shadow-entry.interfaces'
import styles from '~/components/text-styles-shadow-entry/text-styles-shadow-entry.module.scss'
import Delete from '~/assets/icons/remove.svg'

const TextStylesShadowEntry: Component<TextStylesShadowEntryProps> = ({ onChange, onInput, onBlur, onFocus, onDelete, shadow }) => {
  const onOffsetXChange = ({ updateDB = false }) => (value: string) => {
    const newValue = parseFloat(value)
    const isValid = !Number.isNaN(newValue)
    if(updateDB){
      const cleanValue = isValid ? newValue : 0
      onChange({
        ...shadow,
        offsetX: cleanValue
      })
    }else{
      if(isValid){
        onInput({
          ...shadow,
          offsetX: newValue
        })
      }
    }
  }

  const onOffsetYChange = ({ updateDB = false }) => (value: string) => {
    const newValue = parseFloat(value)
    const isValid = !Number.isNaN(newValue)
    if (updateDB) {
      const cleanValue = isValid ? newValue : 0
      onChange({
        ...shadow,
        offsetY: cleanValue
      })
    } else {
      if (isValid) {
        onInput({
          ...shadow,
          offsetY: newValue
        })
      }
    }
  }

  const onBlurChange = ({ updateDB = false }) => (value: string) => {
    const newValue = parseFloat(value)
    const isValid = newValue >= 0
    if (updateDB) {
      const cleanValue = isValid ? newValue : 0
      onChange({
        ...shadow,
        blur: cleanValue
      })
    } else {
      if (isValid) {
        onInput({
          ...shadow,
          blur: newValue
        })
      }
    }
  }

  const onColorChange = ({ updateDB = false }) => (color: string) => {
    const payload = {
      ...shadow,
      color
    }
    if(updateDB){
      onChange(payload)
    }else{
      onInput(payload)
    }
  }

  return (
    <div class={styles.shadowEntry}>
      <TextStyleField
        onChange={onOffsetXChange({ updateDB: true })}
        onInput={onOffsetXChange({ updateDB: false })}
        onFocus={onFocus}
        onBlur={onBlur}
        value={shadow.offsetX}
        prefix='X'
      />
      <TextStyleField
        onChange={onOffsetYChange({ updateDB: true })}
        onInput={onOffsetYChange({ updateDB: false })}
        onFocus={onFocus}
        onBlur={onBlur}
        value={shadow.offsetY}
        prefix='Y'
      />
      <TextStyleField
        onChange={onBlurChange({ updateDB: true })}
        onInput={onBlurChange({ updateDB: false })}
        onFocus={onFocus}
        onBlur={onBlur}
        value={shadow.blur}
        suffix='px'
      />
      <TextStyleColor
        value={shadow.color}
        onChange={onColorChange({ updateDB: true })}
        onInput={onColorChange({ updateDB: false })}
      />
      <RowButton
        icon={Delete}
        onClick={onDelete}
      />
    </div>
  )
}

export default TextStylesShadowEntry