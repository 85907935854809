import { Component, For, Show, createResource, createSignal } from 'solid-js'
import Chevron from '~/assets/icons/chevron-down.svg'
import Warning from '~/assets/icons/warning.svg'
import { FontFamily } from '~/local-fonts'
import { TextStylesWidget } from '~/components/text-style-editor/text-style-editor.interfaces'
import styles from '~/components/text-style-font-select/text-style-font-select.module.scss'
import { getDefaultFontCSS } from '~/services/fonts/font-css'
import { getFontCollections } from '~/services/fonts/font-collection'
import { translations } from '~/translations'

const TextStyleFontSelect: Component<TextStylesWidget> = ({ updateStyles, textStyles }) => {
  const [toggled, setToggled] = createSignal(false)
  const [fontFamilies] = createResource(getFontCollections)
  const fontFamily = () => textStyles?.props?.fontFamily
  const hasFontOnDevice = () => {
    return fontFamilies()?.find(font => font.name === fontFamily().name)
  }

  const onEscapeHandler = (event: KeyboardEvent) => {
    if(event.code === 'Escape'){
      onBlurHandler()
    }
  }

  const onBlurHandler = () => {
    window.removeEventListener('click', onBlurHandler)
    window.removeEventListener('keydown', onEscapeHandler)
    onBlur()
  }

  const onBlur = () => {
    setToggled(false)
  }

  const onSelect = (fontFamily: FontFamily) => () => {
    updateStyles({
      props: {
        fontFamily,
        fontVariant: fontFamily.styles[0]
      }
    })
    setToggled(false)
  }

  const onToggle = () => {
    setToggled(value => !value)
    if(toggled()){
      setTimeout(() => {
        window.addEventListener('click', onBlurHandler)
        window.addEventListener('keydown', onEscapeHandler)
      })
    }
  }

  const optionsClasses = () => ({
    [styles.options]: true,
    [styles.toggled]: toggled()
  })

  return (
    <div class={styles.container}>
      <button
        class={styles.select}
        onClick={onToggle}
      >
        <Show when={fontFamily()}>
          {fontFamily => (
            <div
              class={styles.fontEntry}
              style={getDefaultFontCSS(fontFamily().styles[0])}
              title={!hasFontOnDevice() ? translations().editor.typesetting.fonts.missingFont : undefined}
            >
              <span class={styles.fontName}>{fontFamily().name}</span>

              {!hasFontOnDevice() && (
                <Warning class={styles.missingIcon}/>
              )}
            </div>
          )}
        </Show>

        <Chevron class={styles.chevronIcon} />
      </button>

      <div classList={optionsClasses()}>
        <For each={fontFamilies()}>
          {family => (
            <button
              class={styles.fontEntry}
              style={getDefaultFontCSS(family.styles[0])}
              onClick={onSelect(family)}
            >
              <span class={styles.fontName}>{family.name}</span>
              <span class={styles.count}>({family.styles.length})</span>
            </button>
          )}
        </For>
      </div>
    </div>
  )
}

export default TextStyleFontSelect
